<template>
  <el-dialog :title="title" :visible.sync="visible" class="standardEditDialog" :close-on-click-modal="false" :close-on-press-escape="false" @close="close">
    <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="submit" label-width="120px">
      <el-form-item prop="code" label="标准编码">
        <el-input v-model="dataForm.code" placeholder="请填写标准编码" :maxlength="50"></el-input>
      </el-form-item>
      <el-form-item prop="name" label="标准名称">
        <el-input v-model="dataForm.name" placeholder="请填写标准名称" :maxlength="50"></el-input>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">提交</el-button>
    </template>
  </el-dialog>
</template>
<script>
import debounce from 'lodash/debounce'
import _ from 'lodash'
export default {
  name: 'standardEditDialog',
  data () {
    return {
      visible: false,
      id: null,
      dataForm: {
        name: '',
        code: ''
      },
      dataRule: {
        name: [{ required: true, message: '请填写标准名称', trigger: 'blur' }],
        code: [{ required: true, message: '请填写标准编码', trigger: 'blur' }]
      }
    }
  },
  computed: {
    title () {
      return `${this.id ? '修改' : '新增'}标准`
    }
  },
  methods: {
    init (item) {
      console.log(arguments)
      if (item && item.id) {
        this.id = item.id
        let form = _.cloneDeep(item)
        this.$set(this, 'dataForm', form)
      } else {
        this.id = null
        this.dataForm = this.$options.data().dataForm
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },
    // 关闭窗口
    close () {
      this.visible = false
      this.$emit('reload')
    },
    // 提交数据
    submit: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) return false
        let func = this.id ? 'put' : 'post'
        let form = _.cloneDeep(this.dataForm)
        form.warehouseId = this.pid
        this.$http[func]('/levelStandard', form).then(({ data: res }) => {
          let { code, msg } = res
          if (code !== 0) {
            this.$message.error(msg)
          } else {
            this.close()
          }
        })
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>
<style lang="scss">
.standardEditDialog {
  .fullWidth {
    width: 100%;
  }
}
</style>
